<template>
  <Transition name="fade-fast">
    <SiteNav :page-title="title" v-if="$beet.isReady"/>
  </Transition>
  <router-view @update-title="setTitle"/>
  <Transition name="fade-fast">
    <Footer v-if="$beet.isReady"/>
  </Transition>
  <Gdpr/>
</template>

<script>
import {Footer, SiteNav} from "@/components";
import {useLangRouter} from "@/extensions/langRouter";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  data() {
    return {
      title: null,
    }
  },
  setup() {
    useLangRouter()
  },
  methods: {
    setTitle(newTitle) {
      this.title = newTitle
    }
  },
  components: {Footer, SiteNav, Gdpr},
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    },
    '$route'() {
      switch (this.$route.name) {
        case 'certificate':
          this.title = 'Certificat'
          break
        case 'certificate:en':
          this.title = 'Certificate'
      }
    }
  }
}
</script>
