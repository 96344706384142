<template>
  <div class="form__field">
    <Transition name="label">
      <label :for="fieldName" v-show="fieldContent" :class="['form__label', {'--active': isActive}]">{{ label }}</label>
    </Transition>
    <input type="text" :id="fieldName" :name="fieldName" :placeholder="label" v-model="fieldContent" :class="['form__input', {'--valid': isFieldValid}, {'--active': isActive}]"
           @blur="validateField" @input="validateLastField" :required="isRequired" ref="field" @focus="isActive = true">
    <FormError :message="errorMessage"/>
  </div>
</template>

<script>
import {FormError} from '@/components'

export default {
  name: 'FormField',
  components: {
    FormError
  },
  props: {
    activeEmails: {
      type: Array,
      required: false
    },
    fieldName: {
      type: String,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorMessage: '',
      fieldContent: '',
      isActive: false,
      isFieldValid: false,
      isValidated: false,
      regexps: {
        business: new RegExp(/^[a-zA-ZÀ-ÿ\-' 0-9]{1,50}$/),
        email: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
        firstName: new RegExp(/^[a-zA-ZÀ-ÿ\-' ]{1,50}$/),
        lastName: new RegExp(/^[a-zA-ZÀ-ÿ\-' ]{1,50}$/)
      }
    }
  },
  methods: {
    updateFieldStatus() {
      this.isFieldValid = this.errorMessage === ''
      this.$emit('updateFieldStatus', {fieldName: this.fieldName, isValid: this.isFieldValid, value: this.fieldContent})
    },
    validateField() {
      this.isActive = false
      this.isValidated = true
      if(!this.fieldContent) {
        if(this.$refs.field.required) {
          this.errorMessage = this.$t(`errors.${this.fieldName}.empty`)
        }
        else {
          this.errorMessage = ''
        }
      }
      else {
        if(!this.regexps[this.fieldName].test(this.fieldContent)) {
          this.errorMessage = this.$t(`errors.${this.fieldName}.pattern`)
        }
        else {
          if(this.type === 'email' && this.activeEmails.length && this.activeEmails.includes(this.fieldContent)) {
            this.errorMessage = this.$t(`errors.${this.fieldName}.unavailable`)
          }
          else {
            this.errorMessage = ''
          }
        }
      }
      this.updateFieldStatus()
    },
    validateLastField() {
      if(this.isValidated || this.isLast) {
        this.validateField()
      }
    }
  },
  emits: ['updateFieldStatus'],
}
</script>