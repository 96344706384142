import { createStore } from 'vuex'

export default createStore({
  state: {
    currentCourse: null,
    currentUser: null
  },
  getters: {
    getCurrentCourse(state) {
      return state.currentCourse
    },
    getCurrentUser(state) {
      return state.currentUser
    }
  },
  mutations: {
    updateCurrentCourse(state, newCourse) {
      state.currentCourse = newCourse
    },
    updateCurrentUser(state, newData) {
      state.currentUser = newData
    }
  },
  actions: {
  },
  modules: {
  }
})
