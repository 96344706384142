<template>
  <Transition name="error">
    <p class="form__error" v-if="message">{{message}}</p>
  </Transition>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>