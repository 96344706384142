<template>
<footer :class="['footer', {'--certificate': isCertificatePage || isHomePage }]">
  <div v-if="$beet.isReady" :class="['footer__logo-group', {'--course': isCoursePage}]">
    <a v-if="demixAgregats" :href="demixAgregats.link" target="_blank">
      <img :src="demixAgregats.image" alt="Demix Agrégats" class="footer__logo">
    </a>
    <a v-if="demixBeton" :href="demixBeton.link" target="_blank">
      <img :src="demixBeton.image" alt="Demix Béton" class="footer__logo">
    </a>
  </div>
  <LangRouterLink class="footer__privacy" :to="{name: 'privacy'}">
    {{ $t('terms') }}
  </LangRouterLink>
  <Transition name="fade-fast">
    <LangRouterLink v-if="$beet.isReady && isCoursePage" :to="{name: 'home'}" class="button --red">
      {{$beet.options.general.course.cancel}}
    </LangRouterLink>
  </Transition>
</footer>
</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink";
export default {
  name: 'Footer',
  components: {LangRouterLink},
  computed: {
    demixAgregats() {
      return this.$beet.isReady ? this.$beet.options.general.demixagregats : null
    },
    demixBeton() {
      return this.$beet.isReady ? this.$beet.options.general.demixbeton : null
    },
    isCertificatePage() {
      return this.$route.name === 'certificate' || this.$route.name === 'certificate:en'
    },
    isCoursePage() {
      return this.$route.name === 'course' || this.$route.name === 'course:en'
    },
    isHomePage() {
      return this.$route.name === 'home' || this.$route.name === 'home:en'
    }
  }
}
</script>
