export default {
    gdpr: {
        banner: {
            title: 'Our use of cookies',
            description: 'This website sets cookies on your device. We use this information to improve and personalize your browsing experience. They will only be activated if you accept.',
            accept: 'Accept',
            settings: 'Manage preferences'
        },

        settings: {
            title: 'Our use of cookies',
            description: 'When you visit this site, it may store or retrieve information from your browser, mainly in the form of cookies. This information does not identify you directly; it is used to facilitate browsing and provide a more personalized experience.\n' +
                'Because we respect your right to privacy, you can choose to disable certain types of cookies by changing your default settings.\n' +
                'For more information, visit our Privacy page.',
            essentials: {
                title: 'Strictly Necessary Cookies',
                description: 'Strictly Necessary Cookies are used to improve website functionality such as security, la gestion du réseau and accessibility. You may disable these by customizing your browser settings, but this may affect how the website functions.',
            },
            analytics: {
                title: 'Performance cookies',
                description: 'Google Analytics cookies help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify the user. For more information about cookies, visit our Privacy page.',
            },
            save: 'Save and close'
        },
    }
}