export default {
    errors: {
        business: {
            empty: 'Please enter a business name.',
            pattern: 'Please enter a valid business name.'
        },
        email: {
            empty: 'Please enter an email.',
            pattern: 'Please enter a valid email.',
            unavailable: 'This email has already been associated with a certificate for the current year.'
        },
        finalTest: {
          first: 'Oops! There seem to be errors in your questionnaire.',
          second: 'Please try again.'
        },
        firstName: {
            empty: 'Please enter a first name.',
            pattern: 'Please enter a valid first name.'
        },
        lastName: {
            empty: 'Please enter a last name.',
            pattern: 'Please enter a valid last name.'
        }
    },
    terms: 'Privacy'
}
