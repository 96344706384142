import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        paths: {en: '/'},
        name: 'home',
        component: () => import('@/views/Home')
    },
    {
        path: '/certificat',
        paths: {en: '/certificate'},
        name: 'certificate',
        component: () => import('@/views/Certificate')
    },
    {
        path: '/formations/:courseSlug',
        paths: {en: '/courses/:courseSlug'},
        name: 'course',
        component: () => import('@/views/Course'),
        props: true
    },
    {
        path: '/confidentialite',
        paths: {en: '/privacy'},
        name: 'privacy',
        component: () => import('@/views/Privacy')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', ['en'])

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

export default router
