export default {
    errors: {
        business: {
            empty: 'Veuillez entrer une entreprise.',
            pattern: 'Veuillez entrer une entreprise valide.'
        },
        email: {
            empty: 'Veuillez entrer un courriel.',
            pattern: 'Veuillez entrer un courriel valide.',
            unavailable: 'Cette adresse courriel est déjà associée à un certificat pour l\'année courante.'
        },
        finalTest: {
            first: 'Oups! Il semble qu’il y ait des erreurs dans votre questionnaire.',
            second: 'Veuillez réessayer à nouveau.'
        },
        firstName: {
            empty: 'Veuillez entrer un prénom.',
            pattern: 'Veuillez entrer un prénom valide.'
        },
        lastName: {
            empty: 'Veuillez entrer un nom.',
            pattern: 'Veuillez entrer un nom valide.'
        }
    },
    terms: 'Confidentialité'


}
