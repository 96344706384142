<template>
  <header class="navbar">
    <LangRouterLink :to="{name: 'home'}">
      <img :src="logo" alt="" class="navbar__logo">
    </LangRouterLink>
    <div class="navbar__content">
      <h1 :class="['navbar__hometitle', {'--course': isCoursePage}]">
        {{title}}
      </h1>
      <div class="navbar__lang-group" v-if="!isCoursePage">
        <button :disabled="$i18n.locale === 'en'" class="navbar__lang" @click="$i18n.locale = 'en'">EN</button>
        <span>/</span>
        <button :disabled="$i18n.locale === 'fr'" class="navbar__lang" @click="$i18n.locale = 'fr'">FR</button>
      </div>
    </div>
  </header>
</template>

<script>
import {LangRouterLink} from '@/components/global'

export default {
  name: "SiteNav",
  props: {
    pageTitle: {
      type: String,
      default: null
    }
  },
  components: {
    LangRouterLink
  },
  computed: {
    isCoursePage() {
      return this.$route.name === 'course' || this.$route.name === 'course:en'
    },
    title() {
      return this.pageTitle ? this.pageTitle : 'Demix Formation'
    }
  },
  data() {
    return {
      logo: require('@/assets/images/logo-crh.svg')
    }
  }
}
</script>
